import React from 'react'
import { CMSWrapper } from '../../components/cmsWrapper'
import { Link } from 'gatsby'
import { Navigation } from '../../common/navigation'
import { GlobalHelmet } from '../../common/seo/globalHelmet'
import { Footer } from '../../common/footer'
import { AREA_CARE_PATH } from '../../config/constants'
import { Button } from '../../common/button'

export default function Altenpflegende() {
  return (
    <CMSWrapper>
      <GlobalHelmet title="UKE jukebox - Altenpflegende im UKE" />
      <main>
        <Navigation />

        <header className="bg-img-yellow divider-left">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-10 offset-1 text-center">
              <img
                src="/img/handlettering/handlettering-bei-uns-bleibt-nicht-alles-beim-alten.png"
                alt="Handlettering"
                title="Bei uns bleibt nicht alles beim Alten"
                width="400"
                loading="lazy"
              />
            </div>
          </div>
        </header>

        <section className="bg-blue divider-right">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>Starte bei uns als examinierte Altenpflegekraft durch!</h2>
              <p>
                Bei uns erwartet dich ein einzigartiger Pflegekosmos mit zig
                unterschiedlichen Bereichen, die dir jede Menge
                Entfaltungsmöglichkeiten bieten. Wir sind über 3.500
                Pflegekräfte, die sich um das Wohl der Patient:innen kümmern.
              </p>
              <p>
                Der demografische Wandel, einhergehend mit älter werdenden
                Menschen im Krankenhaus, stellt unseren heutigen pflegerischen
                Klinikalltag vor neue Veränderungen und Herausforderungen, die
                eine Vielfalt an Kompetenzen erfordern.
              </p>
              <p>
                Daher möchten wir deine Expertise als examinierte
                Altenpflegekraft in unseren Pflegeteams integrieren. Mit deinen
                ergänzenden Kompetenzen und deinem Erfahrungsschatz in der
                Pflege von älteren Menschen möchten wir unsere
                Patientenorientierung weiter in den Mittelpunkt unseres Handels
                bringen.
              </p>
              <p>
                Wir freuen uns auf dein gutes Gespür für die Belange älterer
                Menschen.
              </p>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-left text-center">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12 text-center">
              <div className="image-container image-container--static image-container--frame">
                <img
                  src="/img/altenpflege/komm-ins-team.jpg"
                  alt="Komm ins Team"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-md-10 offset-md-1 col-12 text-center">
              <h2 className="mb-1">Komm ins Team des UKE</h2>
              <p>Wir freuen uns auf dich</p>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-right">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>PFLEGEVIELFALT</h2>
              <p>
                Im UKE kannst du als examinierte Altenpflegekraft in mehr als 50
                Bereichen arbeiten. Von Innere Medizin bis Orthopädie. Von
                Psychiatrie bis Neurologie. Und das Beste: Du kannst dir deinen
                Bereich aussuchen!
              </p>
              <p>
                Stöbere durch unsere Stationen und schau, welches Team am besten
                zu dir passt. Du kannst auf den Team-Seiten ganz einfach Kontakt
                aufnehmen, eine Hospitation vereinbaren oder dich direkt
                bewerben.
              </p>
            </div>
            <div className="col-md-10 offset-md-1 col-12 text-center">
              <Button
                color="yellow"
                href="/stationen-fuer-altenpflegende"
                className="px-5"
              >
                Stöbern-Seite für Altenpflegende
              </Button>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-10 offset-md-1 col-12 text-center">
              <p>
                Du möchtest wissen, was dich auf unseren Stationen erwartet?
                <br />
                Dann schau hier in unsere Stellenausschreibung:
              </p>
            </div>
            <div className="col-md-10 offset-md-1 col-12 text-center">
              <Button
                color="yellow"
                href="https://www.uke.jobs/sap(bD1kZSZjPTUwMA==)/bc/bsp/kwp/bsp_eui_rd_uc/main.do?action=to_job_details&back=&pinst_guid=0050569B20E31EEBA8B5671F0CE445BD"
              >
                Bewerben
              </Button>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-left">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-12 text-center">
              <h2>Warum ihr zu uns kommen solltet</h2>
              <h3>Wir leben Vielfalt</h3>
            </div>
          </div>

          <div className="row align-items-center mt-5">
            <div className="col-md-6 offset-md-3 col-12 text-center">
              <div className="image-container image-container--static image-container--frame">
                <img
                  src="/img/altenpflege/wir-leben-vielfalt.jpg"
                  alt="Wir leben vielfalt"
                  loading="lazy"
                />
              </div>
            </div>

            <div className="col-md-10 offset-md-1 col-12">
              <p>
                Unsere Teams sind so vielfältig wie unsere Einsatzgebiete. Wir
                arbeiten eng mit anderen Berufsgruppen, wie beispielsweise,
                Ärzt:innen, Medizinischen Fachangestellten (MFA), Therapeuten
                und die Sozial- und Pflegeberatung zusammen und unterstützen uns
                gegenseitig im Team. Du übernimmst vielfältige und
                verantwortungsvolle Aufgaben - nah an unseren Patient:innen und
                nah an moderner Pflege und Medizin.
              </p>
              <p>
                Nur als Team sind wir stark und wir freuen uns, wenn wir
                voneinander lernen und du deine Expertise bei uns einbringst.
              </p>
            </div>
          </div>

          <div className="row align-items-center mt-5">
            <div className="col-md-10 offset-md-1 col-12 mb-3">
              <h3>Keine "patientenfernen" Tätigkeiten</h3>
            </div>
            <div className="col-md-5 offset-md-1 col-12">
              <p>
                Die Pflege unserer Patient:innen steht bei uns an erste Stelle.
                Damit du dich voll und ganz darauf konzentrieren kannst,
                entlasten wir dich bei den „patientenfernen“ Tätigkeiten:
              </p>
              <p>
                Um die gastronomische Versorgung der Patient:innen kümmert sich
                ein spezielles Serviceteam und das Stellen von Medikamenten
                (zentrale Prüfung, Beschriftung & Verpackung) wird durch das
                UKE-Unit-Dose-System der Hausapotheke übernommen.
              </p>
            </div>

            <div className="col-md-5 col-12 text-center">
              <div className="image-container image-container--static image-container--frame">
                <img
                  src="/img/altenpflege/keine-patientenfernen-taetigkeiten-2.jpg"
                  alt="Keine patientenfernen Tätigkeiten 2"
                  loading="lazy"
                />
              </div>
            </div>
          </div>

          <div className="row align-items-center mt-5">
            <div className="col-md-10 offset-md-1 col-12 mb-3">
              <h3>Keine Suche nach Patientenakten</h3>
            </div>
            <div className="col-md-5 offset-md-1 col-12 text-center">
              <div className="image-container image-container--static image-container--frame">
                <img
                  src="/img/altenpflege/keine-suche-nach-patientenakten-1.jpg"
                  alt="Keine Suche nach Patientenakten 1"
                  loading="lazy"
                />
              </div>
            </div>

            <div className="col-md-5 col-12 text-center">
              <div className="image-container image-container--static image-container--frame">
                <img
                  src="/img/altenpflege/keine-suche-nach-patientenakten-2.jpg"
                  alt="Keine Suche nach Patientenakten 2"
                  loading="lazy"
                />
              </div>
            </div>

            <div className="col-md-10 offset-md-1 col-12">
              <p>
                Im UKE arbeiten wir bereits seit einigen Jahren mit der
                elektronischen Patientenakte. So müssen unsere Krankenschwestern
                und Krankenpfleger nicht ständig nach Papierakten suchen, wie es
                in vielen anderen Häusern noch der Fall ist. Wir nutzen diese
                Zeit für das Wesentliche: Die Pflege unserer Patienten.
              </p>
            </div>
          </div>

          <div className="row align-items-center mt-5">
            <div className="col-md-10 offset-md-1 col-12 mb-3">
              <h3>Unser Einarbeitungskonzept für dich</h3>
            </div>
            <div className="col-md-6 offset-md-3 col-12 text-center">
              <div className="image-container image-container--static image-container--frame">
                <img
                  src="/img/altenpflege/unsere-einarbeitungskompetenz-fuer-dich.jpg"
                  alt="Unsere Einarbeitungskompetenz für dich"
                  loading="lazy"
                />
              </div>
            </div>

            <div className="col-md-10 offset-md-1 col-12">
              <p>
                Bei uns startest du immer mit{' '}
                <strong>übergeordneten UKE-Einführungstagen</strong>. Danach
                fängt die <strong>stationsspezifische Einarbeitung</strong> in
                deinem neuen Bereich an. Darüber hinaus haben wir uns für dich
                ein zusätzliches Fortbildungsprogramm überlegt, welches an
                unserer UKE-Akademie für Bildung und Karriere (ABK) stattfinden
                wird. Unsere Dozent:innen werden dir nützliches „Handwerkszeug“
                vermitteln und dein Hintergrundwissen vertiefen, um dich für den
                praktischen Alltag auf deiner künftigen Station fit zu machen.
                Feste Ansprechpartner/- innen, Schulungstage, regelmäßiges
                Feedback, inhaltliche Einarbeitungskonzepte pro Bereich und
                langsame Steigerung bei der selbsttändigen Übernahme der
                Patientenversorgung sind unsere Stärken.
              </p>
            </div>
          </div>

          <div className="row align-items-center mt-5">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>... was du noch erwarten kannst:</h2>
              <ul>
                <li className="d-block mt-2">
                  Du wirst in unserem{' '}
                  <strong>
                    <a
                      className="bg-dark-blue"
                      href="https://www.uke.de/allgemein/karriere/das-bieten-wir-als-arbeitgeber/verguetung-tarifvertraege/index.html"
                    >
                      Tarifvertrag (TVöD-K)
                    </a>
                  </strong>{' '}
                  genauso eingruppiert wie unsere Gesundheits- und
                  Krankenpflegende (P7 oder P8 – je nach Bereich)
                </li>
                <li className="d-block mt-2">
                  <strong>
                    <a
                      className="bg-dark-blue"
                      href="https://www.uke.de/organisationsstruktur/direktion-f%C3%BCr-patienten-pflegemanagement/pflege-im-uke/arbeiten-in-der-uke-pflege/flexible-arbeitszeiten/index.html"
                    >
                      Mitarbeiterfreundliche Dienstplangestaltung:
                    </a>
                  </strong>
                  <ul className="ps-5">
                    <li>Die Dienstplanung erfolgt ca. 6 Wochen im Voraus.</li>
                    <li>Du arbeitest maximal sieben Tage hintereinander.</li>
                    <li>Du arbeitest maximal vier Nächte am Stück.</li>
                    <li>
                      Wir versuchen dabei stets deine Wünsche bei der
                      Dienstplangestaltung zu berücksichtigen.
                    </li>
                  </ul>
                </li>
                <li className="d-block mt-2">
                  <strong>Kostenübernahme und zeitliche Freistellung</strong>{' '}
                  für Fort- und Weiterbildungen
                </li>
                <li className="d-block mt-2">
                  <strong>Qualifikation innerhalb des Mentorensystems</strong>{' '}
                  (Hygienementor/in, Schmerzmentor/in, Mentor/in für neue
                  Mitarbeiter/Auszubildende/Praktikanten, Mentor/in für Menschen
                  mit Demenz)
                </li>
                <li className="d-block mt-2">
                  <strong>Weitere Qualifikationsoptionen</strong> bspw. zur/m,
                  Praxisanleiter/in, Wundexpertin/-experten
                </li>
                <li className="d-block mt-2">
                  <strong>
                    Teilnahme an Kongressen und an fachspezifischen internen
                    Fortbildungen
                  </strong>{' '}
                  (Demenz, Transplantationen, Medikamenten- und
                  Notfallmanagement, Wundversorgung etc.)
                </li>
                <li className="d-block mt-2">
                  <strong>Teilnahme an Fortbildungen</strong> unser UKE-Akademie
                  für Bildung und Karriere
                </li>
                <li className="d-block mt-2">
                  <strong>
                    <a
                      className="bg-dark-blue"
                      href="https://www.uke.de/allgemein/karriere/das-bieten-wir-als-arbeitgeber/arbeitgebervorteile/index.html"
                    >
                      Viele Mitarbeiterangebote und Benefits
                    </a>
                  </strong>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-right">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-12 text-center">
              <h2>Geschichten mit Herz</h2>
              <p>
                Bei uns kannst du selbst ein Teil solch toller Geschichten
                werden. Erlebe, wie bunt die Pflege im UKE ist – auch als
                Altenpflegende.
              </p>
            </div>
          </div>

          <div className="row align-items-center mt-5">
            <div className="col-md-5 offset-md-1 col-12">
              <div className="image-container image-container--static image-container--frame">
                <img
                  src="/img/altenpflege/daniela-jung-1.jpg"
                  alt="Portrait: Daniela Jung"
                  loading="lazy"
                />
              </div>
            </div>

            <div className="col-md-5 col-12">
              <h3>
                <q>
                  Das UKE bietet mir als gelernte Altenpflegerin maximale
                  Möglichkeiten
                </q>
              </h3>
              <p>
                Daniela Junge war mit ihrer Arbeit in der Altenpflege zufrieden,
                aber sie war auch neugierig und wollte andere Wege der Pflege
                entdecken. Wie sie im UKE die Chancen zur beruflichen
                Weiterentwicklung nutzte, berichtet sie{' '}
                <Link to={`${AREA_CARE_PATH}/den-wechsel-habe-ich-nie-bereut`}>
                  hier
                </Link>
                .
              </p>
            </div>
          </div>

          <div className="row align-items-center mt-5">
            <div className="col-md-5 offset-md-1 col-12">
              <h3>
                <q>Man ist nie zu alt, wenn man Lust aufs Lernen hat</q>
              </h3>
              <p>
                Als Marc-Oliver Henk am UKE startete, war er Ende 40, hatte
                bereits ein facettenreiches Berufsleben hinter sich und in der
                Altenpflege viele Jahre lang mit Freude und Engagement
                gearbeitet. Die Zeit war reif für neue{' '}
                <Link to={`${AREA_CARE_PATH}/bereit-fuer-den-sprung`}>
                  Chancen
                </Link>
                .
              </p>
            </div>

            <div className="col-md-5 col-12 text-center">
              <div className="image-container image-container--static image-container--frame">
                <img
                  src="/img/altenpflege/marc-oliver-henk-1.jpg"
                  alt="Portrait: Marc Oliver Henk"
                  loading="lazy"
                />
              </div>
            </div>
          </div>

          <div className="row align-items-center mt-5">
            <div className="col-md-10 offset-md-1 col-12">
              <h3>Schreibe deine eigene Geschichte</h3>
              <p>
                Du findest die Erfahrungsberichte von Marc-Oliver und Jennifer
                so beeindruckend wie wir und möchtest deine eigene Geschichte im
                UKE schreiben? Dann starte jetzt als examinierte
                Altenpflegekraft im UKE durch!
              </p>
              <p>
                Stöbere durch unsere Stationen und schau, welches Team am besten
                zu dir passt. Du kannst auf den Team-Seiten ganz einach Kontakt
                aufnehmen, eine Hospitation vereinbaren oder dich direkt
                bewerben.
              </p>
            </div>
            <div className="col-md-10 offset-md-1 col-12 text-center my-5">
              <Button
                color="yellow"
                href="/stationen-fuer-altenpflegende"
                className="px-5"
              >
                Stöbern-Seite für Altenpflegende
              </Button>
            </div>
          </div>
        </section>

        <section className="mt-3">
          <figure>
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className="row align-items-center">
                  <p className="col-12">
                    Wenn du von unserer Vielfalt erschlagen bist, kein Problem:
                    Schreibe Antje Prütz einfach eine E-Mail oder ruf sie direkt
                    an. Sie wird dir mit Rat zur Seite stehen und mit dir über
                    deine Vorstellungen und Wünsche sprechen.
                  </p>

                  <div className="col-md-6 order-1 text-center">
                    <img
                      src="/img/altenpflege/antje_pruetz.jpg"
                      alt="Portrait: Antje Prütz"
                      title="Antje Prütz"
                      loading="lazy"
                    />
                  </div>

                  <div className="col-md-6 order-md-2 order-3">
                    <ul className="contact-channels">
                      <li className="phone">
                        <a href="tel:+4940741059034">040 741 05 90 34</a>
                      </li>
                      <li className="mobile">
                        <a href="tel:+4915222842229">0 152 22 84 22 29</a>
                      </li>
                      <li className="email">
                        <a href="mailto:a.pruetz@uke.de">a.pruetz@uke.de</a>
                      </li>
                    </ul>
                  </div>

                  <div className="col-md-6 order-md-3 order-2">
                    <figcaption className="mb-4">Antje Prütz</figcaption>
                  </div>
                </div>
              </div>
            </div>
          </figure>
        </section>
        <Footer />
      </main>
    </CMSWrapper>
  )
}
